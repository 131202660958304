/**
 * We can have multiple theme drived from @Theme interface.
 * @export
 * @interface Theme
 */
export interface Theme {
  name: Tool,
  properties: any
}

export enum Tool {
  neutral = 'neutral',
  referralIdentifier = 'referralidentifier',
  treatmentoptimiser = 'treatmentoptimiser'
}

export const neutral: Theme = {
  name: Tool.neutral,
  properties: {
    '--primary-color': '#03a784',
    '--primary-color-darkened': '#006148',
    '--button-hover': '#006148',
    '--button-active': '#03a784',
    'theme-color': '#006148'
  }
}

export const referralIdentifier: Theme = {
  name: Tool.referralIdentifier,
  properties: {
    '--primary-color': '#03a784',
    '--primary-color-darkened': '#006148',
    '--button-hover': '#006148',
    '--button-active': '#03a784',
    'theme-color': '#006148'
  }
}

export const treatmentOptimiser: Theme = {
  name: Tool.treatmentoptimiser,
  properties: {
    '--primary-color': '#2F94DA',
    '--primary-color-darkened': '#05518C',
    '--button-active': '#2f94da',
    '--button-hover': '#05518c',
    '--secondary-color-darkened': '#091c77',
    '--secondary-color': '#0f2cbb',
    '--color-sky-blue': '#E8EFF9',
    '--color-an-blue': '#2F94DA',
    '--color-white': '#ffffff',
    '--color-black': '#000000',
    '--alert-color': '#E50900',
    '--body-bg-gray': '#f7f7f7',
    '--body-text-gray': '#e6e8e9',
    '--body-text-dark-gray': '#A7A9AC',
    '--button-disabled-bg-color': '#d8d8d8',
    '--header-height': '50px',
    '--top-bar-height': '40px',
    '--default-z-index': '0',
    '--header-z-index': '1',
    '--search-bar-z-index': '2',
    '--backdrop-z-index': '3',
    '--side-nav-index': '4',
    '--modal-overlay-z-index': '5',
    '--modal-z-index': '6',
    '--modal-z-index-priority': '7',
    '--loader-overlay-z-index': '8',
    'theme-color': '#05518C'
  }
}
