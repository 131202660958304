import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { treatmentOptimiser, referralIdentifier } from 'app/interfaces/theme.interface'
import { LocalDataService } from 'app/services/local-data.service'
import { ThemeService } from 'app/services/theme.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-shapes',
  templateUrl: './shapes.component.html',
  styleUrls: ['./shapes.component.scss']
})
export class ShapesComponent implements OnInit, OnDestroy {
  public referralIdentifier: string = referralIdentifier.name
  public treatmentOptimiser: string = treatmentOptimiser.name
  public tool: string
  private unsubscribe$ = new Subject()
  @Input() public forceBoth = false

  constructor(private themeService: ThemeService,
              private localDataService: LocalDataService) {
  }

  public ngOnInit(): void {
    const lastActiveTool = this.localDataService.getLastActiveTool()
    // set colour based on current tool
    this.setClass(lastActiveTool)
    // check if shapes are forced 'both' class
    if (this.forceBoth) {
      this.tool = 'BOTH'
    } else {
      // keep colour updated when theme changes
      this.themeService.theme()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(val => {
                this.setClass(val)
              })
    }
  }

  private setClass(val) {
    switch (val) {
      case this.referralIdentifier: {
        this.tool = 'referralIdentifier'
        break
      }
      case this.treatmentOptimiser: {
        this.tool = 'treatmentOptimiser'
        break
      }
      default: {
        this.tool = 'BOTH'
        break
      }
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
