import { Injectable } from '@angular/core'
import { treatmentOptimiser, neutral, referralIdentifier, Theme, Tool } from 'app/interfaces/theme.interface'
import { logger } from 'app/utils/logger'
import cssVars from 'css-vars-ponyfill'
import { Observable, Subject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private static TAG: string = 'serv::Theme'
  private availableThemes: Theme[] = [referralIdentifier, treatmentOptimiser, neutral]
  private _theme: Subject<string> = new Subject<string>()
  private activeTheme: Theme

  constructor() {
    this.theme().subscribe( name => {
      logger.debug('%s - setTheme( %s )', ThemeService.TAG, name)
      if (name == null) name = Tool.neutral
      const result = this.availableThemes.filter(theme => theme.name.toLowerCase() === name.toLowerCase())
      if (result.length > 0) {
        this.activeTheme = result[0]
      } else {
        this.activeTheme = referralIdentifier
      }
      this.setCssVariables(this.activeTheme.properties)
      const metaThemeColor = document.querySelector('meta[name=theme-color]')
      if (metaThemeColor != null) metaThemeColor.setAttribute('content', this.activeTheme.properties['theme-color'])
      const metaThemeColoriOS = document.querySelector('meta[name=apple-mobile-web-app-status-bar-style]')
      if (metaThemeColoriOS != null) metaThemeColoriOS.setAttribute('content', this.activeTheme.properties['theme-color'])
    })
  }

  public theme(): Observable<string> {
    return this._theme.pipe(distinctUntilChanged())
  }

  public getActiveTheme() {
    return this.activeTheme
  }

  public updateTheme(themeName) {
    this._theme.next(themeName)
  }

  private setCssVariables(variables: { }) {
    cssVars({ // polyfill for IE
      onlyLegacy: true,
      watch: false,
      variables
    })
  }
}
