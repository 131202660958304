import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService, Event } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ThemeService } from 'app/services/theme.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { environment } from 'environments/environment'
import { treatmentOptimiser, referralIdentifier } from '../../interfaces/theme.interface'
import { ScrollService } from '../../services/scroll.service'
import { DialogConfig } from '../dialog/dialog-config'
import { DialogService } from '../dialog/dialog.service'

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: FadeInAnimation.animations
})
export class SideNavComponent implements OnInit {
  @Input() public navigationItems?: any[]
  @Input() public showSideNav = true
  @Output() public readonly onLangToggled = new EventEmitter<any>()
  @Output() public readonly onNavigate = new EventEmitter<any>()
  public environment = environment
  public activeTheme: string
  public treatmentOptimiser: string = treatmentOptimiser.name
  public referralIdentifier: string = referralIdentifier.name
  public brand: string = ''
  public support: string
  public terms: string
  public privacy: string
  public footerCookie: any

  constructor(
    private navigate: NavigationService,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService,
    private dialog: DialogService,
    private translate: TranslateService,
    private localData: LocalDataService,
    private communication: CommunicationService,
    private injector: Injector
  ) {
  }

  public ngOnInit() {
    this.themeService.theme().subscribe(name => {
      this.activeTheme = name
    })
    this.updateBrand()
  }

  private async updateBrand() {
    const { detected, config } = await this.communication.getCountryConfig()
    const detectedCountry = config.find(e => e.code === detected.country)
    if (detectedCountry && detectedCountry.branding) {
      this.brand = detectedCountry.branding.logo
    }

    // this.support = detectedCountry.supportURL.default
    // TODO: set correct URL
    this.support = "mailto:support@lothar-medtec.de";
    this.terms = detectedCountry.termsAndConditionsUrl.default
    this.privacy = detectedCountry.privacyNoticeUrl.default

    // TODO: fix TypeError: Cannot read properties of undefined (reading 'en')
    // if (detectedCountry.supportURL[detected.language]) {
    //   this.support = detectedCountry.supportURL[detected.language]
    // }

    if (detectedCountry.termsAndConditionsUrl[detected.language]) {
      this.terms = detectedCountry.termsAndConditionsUrl[detected.language]
    }

    if (detectedCountry.privacyNoticeUrl[detected.language]) {
      this.privacy = detectedCountry.privacyNoticeUrl[detected.language]
    }

    let bannerCookie = detectedCountry.bannerCookie.default
    const head = document.getElementsByTagName('head')[0]
    const s = document.createElement('script')

    if (document.getElementById('bannerCookie')) {
      document.getElementById('bannerCookie').remove()
    }

    if (document.getElementById('CookieReportsBannerAZ')) {
      document.getElementById('CookieReportsBannerAZ').remove()
    }

    if (document.getElementById('CookieReportsPanel')) {
      document.getElementById('CookieReportsPanel').remove()
    }

    if (detectedCountry.bannerCookie[detected.language]) {
      bannerCookie = detectedCountry.bannerCookie[detected.language]
    }
    s.src = bannerCookie
    s.type = 'text/javascript'
    s.id = 'bannerCookie'
    head.appendChild(s)

    // TODO: set correct footer Cookie
    // this.footerCookie = detectedCountry.footerCookie.default
    // if (detectedCountry.footerCookie[detected.language]) {
    //   this.footerCookie = detectedCountry.footerCookie[detected.language]
    // }
    this.footerCookie = "https://example.com";
  }
  public jumpTo(e: any, item: any): void {
    this.onNavigate.emit()
    e.preventDefault()
    if (item.status === 'clickable') {
      this.navigate.jumpTo(item.id)
      this.analyticsService.event(Event.NavChosen, { ref_id1: item.id })
    }
  }
  public openPrivacyDialog(e) {
    e.preventDefault()
    const config = new DialogConfig()
    config.backDrop = true
    config.title = this.translate.instant('dialog.privacy.title')
    config.subTitle = this.translate.instant('dialog.privacy.subtitle')
    config.content = this.translate.instant('dialog.privacy.content')
    config.withButtons = false
    this.dialog.open(null, config)
  }
  public openDisclaimerDialog(e) {
    e.preventDefault()
    const config = new DialogConfig()
    config.backDrop = true
    config.title = this.translate.instant('dialog.disclaimer.title')
    config.subTitle = this.translate.instant('dialog.disclaimer.subtitle')
    config.content = this.translate.instant('dialog.disclaimer.content')
    config.withButtons = false
    this.dialog.open(null, config)
  }

  /* public openCookieDialog(e) {
    e.preventDefault()
    const config = new DialogConfig()
    config.backDrop = true
    config.title = this.translate.instant('dialog.cookie.title')
    config.subTitle = this.translate.instant('dialog.cookie.subtitle')
    config.content = this.translate.instant('dialog.cookie.content')
    config.withButtons = false
    this.dialog.open(null, config)
  } */

  public cookiePolicyURL() {
    const locale = this.localData.getLocale()
    return `/assets/cookies/${locale.language}-${locale.country}.html`
  }

  public updateScrollLock() {
    const scrollService = this.injector.get(ScrollService)
    scrollService.unlockBody()
  }
}
