import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { IllegalArgError, throwErr } from 'app/utils/lang-utils'
import { logger } from 'app/utils/logger'
import Amplify from 'aws-amplify'
import * as Bowser from 'bowser'
import { environment } from 'environments/environment'
import { promisify } from 'es6-promisify'
import * as _ from 'lodash'
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, retry, shareReplay, takeUntil, tap } from 'rxjs/operators'
import { AnalyticsService, Prop } from './analytics'
import { isUserDetails, LocalDataService } from './local-data.service'
import { ThemeService } from './theme.service'

export class BuildInfo {
  public version: string = '-'
  public buildno: string = '-'
  public variant: string = '-'
}

interface Properties { [key: string]: string }

@Injectable()
export class CommunicationService {

  private readonly TAG = 'serv::Communication'
  private versionUpdates = new BehaviorSubject<BuildInfo>(new BuildInfo())
  private token: string
  private userName: string
  private shouldAskOverrideGeoblock = false

  public cacheUserSettings: SettingsResponse
  private reload = new Subject<void>()
  private cashedCountries: { [key: string]: Observable<any> } = { }

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private localData: LocalDataService,
    private analytics: AnalyticsService,
    private themeService: ThemeService) { }

  public getPage(data: any, direction: string): Observable<PnResponse> {
    logger.debug('%s - getPage(data: %o, dir: %s)', this.TAG, data, direction)
    const url = environment.API_HOST + environment.ENDPOINT + '/step/' + direction
    return this.sendRequest(url, data)
  }

  public getAssessment(data: any): Observable<PnResponse> {
    const url = environment.API_HOST + environment.ENDPOINT + '/assessment'
    return this.sendRequest(url, data)
  }

  public putSettings(data: any): Observable<PnResponse> {
    this.reloadUserSettings()
    const url = environment.API_HOST + environment.ENDPOINT + '/settings'
    return this.putRequest(url, data)
  }

  public reloadPartialStep(data: any, id: any): Observable<PnResponse> {
    const url = environment.API_HOST + environment.ENDPOINT + '/partial-step/' + id
    return this.sendRequest(url, data)
  }

  public async login(username: string, password: string) {
    const authUser = await Amplify.Auth.signIn(username.toLowerCase(), password)
    if (authUser.signInUserSession != null) {
      const accessToken = authUser.signInUserSession.accessToken.jwtToken
      this.userName = username;
      await this.patientLogin(accessToken);
    } else {
      if (authUser.challengeName != null) {
        throwErr({ code: 'NeedComplete' })
      } else {
        // throwErr({ code: 'Generic', message: 'some Message' })
        throwErr({ code: 'Generic' })
      }
    }
  }

  public async forgotPassword(username: string) {
    return this.http.put<any>(
      environment.API_HOST + environment.ENDPOINT + "/user-management/reset-password", {email: username}, { headers: this.headers(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  public async startConsultation(data) {
    if (data === { }) this.logout()
    const url = environment.API_HOST + environment.ENDPOINT + `/start-consultation?username=${encodeURIComponent(this.userName)}`;
    return this.sendRequest<PnResponse>(url, data).toPromise()
  }

  public signUp(data: SignUpRequest): Observable<PnResponse> {
    const url = environment.API_HOST + environment.ENDPOINT + '/register'
    return this.sendRequest(url, data)
  }

  public getToolForCountry(data): Promise<string[]> {
    return new Promise((resolve, reject) => {
      resolve(['treatmentoptimiser'])
    })
    // const url = environment.API_HOST + environment.ENDPOINT + '/country-config'
    // return this.getRequest(url).toPromise().then(resp => {
    //   const res = resp.config.find(e => e.code === data.toUpperCase())
    //   if (!!res && !!res.variants) return res.variants
    //   else return null
    // })
  }

  public getCountryConfig(forceServerCall?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve({
        config: [{ code: 'nl', label: 'Nederlands',
          languages: [{ code: 'en', label: 'English' }, { code: 'nl', label: 'Nederlands' }],
          languageDirection: { en: 'ltr', nl: 'ltr' },
          bannerCookie: { en: 'https://lothar-medtec.de', nl: 'https://lothar-medtec.de' },
          termsAndConditionsUrl: { en: 'https://lothar-medtec.de', nl: 'https://lothar-medtec.de' },
          privacyNoticeUrl: { en: 'https://lothar-medtec.de', nl: 'https://lothar-medtec.de' },
          variants: ['treatmentoptimiser'] }],
        detected: { country: 'nl', language: 'nl' }
      })
    })
    const url = environment.API_HOST + environment.ENDPOINT + '/country-config'
    if (forceServerCall) {
      // leave free the Accept-Language to favour autodetect
      return this.getRequest(url, false).toPromise()
    }
    const acceptLang = this.computeAcceptLanguageHeader()
    if (!this.cashedCountries[acceptLang]) {
      this.cashedCountries[acceptLang] = this.getRequest(url).pipe(
        shareReplay(1))
    }
    return this.cashedCountries[acceptLang].toPromise()
  }

  public logout() {
    this.token = null
    // resetting variant
    const info = _.clone(this.versionUpdates.value)
    info.variant = '-'
    this.versionUpdates.next(info)
    this.reloadUserSettings()
    Amplify.Auth.signOut()
  }

  public async autoLogin(): Promise<boolean> {
    let currentSession
    let cognitoUser

    try {
      currentSession = await Amplify.Auth.currentSession()
      cognitoUser = await Amplify.Auth.currentAuthenticatedUser()
    } catch (e) {
      // no current session or auth user, no biggie
      return false
    }
    try {
      if (!this.token) {
        const refreshSession = promisify(cognitoUser.refreshSession.bind(cognitoUser))
        const { accessToken } = await refreshSession(currentSession.getRefreshToken())
        logger.info('%s - autoLogin() accessToken: %o', this.TAG, accessToken)
        this.userName = cognitoUser.username;
        return this.patientLogin(accessToken.jwtToken)
      } else {
        return true
      }
    } catch (e) {
      // failed in refresh token, with logging.
      logger.warn('Unable to refresh Token', e)
    }
    return false
  }

  public buildInfo(): Observable<BuildInfo> {
    return this.versionUpdates.pipe(distinctUntilChanged())
  }

  public genericRequest(action: string, spiro: any): any {
    return this.sendRequest(environment.API_HOST + environment.ENDPOINT + action, spiro).toPromise();
  }

  private getSettings(): Promise<SettingsResponse> {
    const url = environment.API_HOST + environment.ENDPOINT + `/settings?username=${encodeURIComponent(this.userName)}`
    return this.http.get<SettingsResponse>(url,
      { headers: this.headers(), responseType: 'json' })
      .pipe(map((resp: SettingsResponse) => {
        const userDetails = { ...resp, ...{ role: resp.role || '' } }
        // type-checking error resp
        if (!isUserDetails(userDetails.user)) {
          throw new IllegalArgError('Not really an user detail!')
        }
        this.analytics.setProp(Prop.DmdpUserId, resp.user.id)
        return userDetails
      }),
        takeUntil(this.reload),
        shareReplay(1)).toPromise()
  }

  private reloadUserSettings() {
    // Calling next will complete the current cache instance
    this.reload.next()

    // Setting the cache to null will create a new cache the
    // next time 'getSettings' is called
    this.cacheUserSettings = null
  }

  public async getUserSettings(): Promise<SettingsResponse> {
    if (!this.cacheUserSettings) {
      this.cacheUserSettings = await this.getSettings()
      return this.cacheUserSettings
    } else {
      return this.cacheUserSettings
    }
  }

  public async getMfaCode(): Promise<any> {
    return this.sendRequest(environment.API_HOST + environment.ENDPOINT + `/user-management/mfa-code?username=${encodeURIComponent(this.userName)}&locale=${this.localData.getLocale().language}`, {}).toPromise();
  }

  public async sendMfaCode(mfaCode: string): Promise<any> {
    return this.http.put<any>(
      environment.API_HOST + environment.ENDPOINT + `/user-management/mfa-code?locale=${this.localData.getLocale().language}`, { email: this.userName, code: mfaCode }, { headers: this.headers(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  public async getUsers(): Promise<any> {
    return this.getAnyUrl(`/user-management/users?locale=${this.localData.getLocale().language}&date=${new Date().getTime()}`).toPromise();
  }

  public async addUser(user: any): Promise<any> {
    return this.sendRequest(environment.API_HOST + environment.ENDPOINT + `/user-management/user?locale=${this.localData.getLocale().language}`, user).toPromise();
  }

  public async updateUser(user: {email: string, firstname: string, name: string, organization: string, language?: string, role?: string, asq?: string, lufu?: string}): Promise<any> {
    this.reloadUserSettings();
    return this.http.put<any>(
      environment.API_HOST + environment.ENDPOINT + '/user-management/user', user, { headers: this.headers(), observe: 'response', responseType: 'json', params: {locale: this.localData.getLocale().language} }
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  public async deleteUser(emailObject: {email: string}): Promise<any> {
    return this.http.delete<any>(
      environment.API_HOST + environment.ENDPOINT + '/user-management/user', { headers: this.headersDelete(), observe: 'response', params: {username: emailObject.email, locale: this.localData.getLocale().language}}
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  public async disableUser(emailObject: {email: string}): Promise<any> {
    return this.http.put<any>(
      environment.API_HOST + environment.ENDPOINT + "/user-management/user/disable", emailObject, { headers: this.headers(), observe: 'response', responseType: 'json', params: {locale: this.localData.getLocale().language}}
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  public async enableUser(emailObject: {email: string}): Promise<any> {
    return this.http.put<any>(
      environment.API_HOST + environment.ENDPOINT + "/user-management/user/enable", emailObject, { headers: this.headers(), observe: 'response', responseType: 'json', params: {locale: this.localData.getLocale().language} }
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      })).toPromise();
  }

  // CODE REVIEW why this? We should hide the details of URL, not expose them
  // if this is needed, then I would expect this to be called by every other GET in this file
  public getAnyUrl(url, retries = 0): Observable<any> {
    return this.http.get<any>(environment.API_HOST +
      environment.ENDPOINT +
      url, { headers: this.headers(), observe: 'response', responseType: 'json' })
      .pipe(
        retry(retries),
        map((resp: any) => {
        return resp.body
      }))
  }

  private downLoadFile(data: any, type: string, filename: string = 'report.pdf') {
    if (navigator.msSaveBlob) { // IE11 and Edge 17-
      const blob = new Blob([data], { type })
      navigator.msSaveBlob(blob, filename)
    } else {
      const browser = Bowser.getParser(window.navigator.userAgent)
      const isSafari13 = browser.satisfies({ mobile: { safari: '>=13' } }) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && browser.satisfies({ safari: '>=13' }))
      // this is forced to trigger a download instead of in-page preview in iOS 13
      if (isSafari13) {
        type = 'application/octet-stream'
      }
      const blob = new Blob([data], { type })
      // we need to turn into dataURI to have it work on iOS 12
      const reader = new FileReader()
      reader.onloadend = () => {
        const a = document.createElement('a')
        a.href = reader.result as string
        a.download = filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.parentNode.removeChild(a)
      }
      reader.readAsDataURL(blob)
    }
  }

  public downloadReport(context: any) {
    const url = environment.API_HOST + environment.ENDPOINT + '/report'
    this.http.post(url, context,
      { headers: this.headers(), observe: 'response', responseType: 'arraybuffer' }
    ).subscribe(response => {
      let filename = response.headers.get('content-disposition')
      const startIndex = filename.indexOf('filename=') + 10
      const endIndex = filename.length - 1
      filename = filename.substring(startIndex, endIndex)
      const type = response.headers.get('content-type')
      this.downLoadFile(response.body, type, filename)
    })
  }

  public downloadReferralLetter(context: any) {
    const url = environment.API_HOST + environment.ENDPOINT + '/referralletter'
    this.http.post(url, context,
      { headers: this.headers(), observe: 'response', responseType: 'arraybuffer' }
    ).subscribe(response => {
      let filename = response.headers.get('content-disposition')
      if (filename) {
        const startIndex = filename.indexOf('filename=') + 10
        const endIndex = filename.length - 1
        filename = filename.substring(startIndex, endIndex)
      } else {
        filename = 'ReferralLetter.docx'
      }
      const type = response.headers.get('content-type')
      this.downLoadFile(response.body, type, filename)
    })
  }

  public sendEMR(context: any) {
    const url = environment.API_HOST + environment.ENDPOINT + '/emr'
    return this.http.post(url, context,
      { headers: this.headers(), observe: 'response', responseType: 'text' }
    ).pipe(map(resp => {
      return resp.body
    })).toPromise()
  }

  public async patientLogin(accessToken?: string): Promise<boolean> {
    const url = environment.API_HOST + environment.ENDPOINT + '/login'
    const data: any = accessToken ? { accessToken } : { }
    await this.sendRequest<LoginResponse>(url, data).pipe(
      tap(response => {
        if (!isLoginResponse(response)) {
          throw throwErr({ code: 'Generic' })
        }
        this.token = response.token
      })
    ).toPromise()
    return true
  }

  public getAccessToken() {
    return this.token
  }

  private updateBuildInfo(resp) {
    // intercepting the version headers and notifying the Subject
    const info = new BuildInfo()
    if (resp.headers.has('x-pnt-version')) {
      info.version = resp.headers.get('x-pnt-version')
    }
    if (resp.headers.has('x-pnt-buildno')) {
      info.buildno = resp.headers.get('x-pnt-buildno')
    }
    if (resp.headers.has('x-pnt-variant')) {
      info.variant = resp.headers.get('x-pnt-variant')
    }
    this.versionUpdates.next(info)
    this.themeService.updateTheme(info.variant)
  }

  // Utils
  private sendRequest<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(
      url, data, { headers: this.headers(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((resp: any) => {
        this.updateBuildInfo(resp)
        return resp.body
      }))
  }

  private computeAcceptLanguageHeader(): string | undefined {
    const { language: savedLang, country: savedCountry } = this.localData.getLocale()
    const sect = [] as string[]

    if (savedLang != null) {
      sect.push(savedLang)
    }
    if (savedCountry != null) {
      sect.push(savedCountry.toUpperCase())
    }
    if (sect.length > 0) {
      return sect.join('-')
    } else {
      return this.translate.currentLang
    }
  }

  private eventuallyFillWithAcceptLanguage(kv: Properties) {
    this.shouldAskOverrideGeoblock = this.localData.getCountryOverride()
    const acceptLang = this.computeAcceptLanguageHeader()
    if (acceptLang != null) {
      kv['accept-language'] = acceptLang
    }
    // should by chance assign this to a null-coerced value, then remove
    // to allow browser XHR to put default
    if (kv['accept-language'] == null) {
      delete kv['accept-language']
    }

    logger.debug('%s - accept-lang: %s', this.TAG, kv['accept-language'])
    if (this.shouldAskOverrideGeoblock) {
      kv['x-pnt-geoblock'] = 'override'
    }
    return kv
  }

  private getRequest(url: string, includeLang = true): Observable<any> {
    const httpHeaders: { [key: string]: string; } = { accept: 'application/json' }
    this.eventuallyFillWithAcceptLanguage(httpHeaders)
    if (!includeLang) {
      httpHeaders['accept-language'] = '*'
    }
    if (this.token) {
      httpHeaders.authorization = `Bearer ${this.token}`
    }
    return this.http.get<any>(
      url,
      { headers: new HttpHeaders(httpHeaders), observe: 'response', responseType: 'json' }
    ).pipe(map((resp: any) => {
      this.updateBuildInfo(resp)
      return resp.body
    }))
  }

  private putRequest(url: string, data: any): Observable<PnResponse> {
    return this.http.put<PnResponse>(
      url,
      data,
      { headers: this.headers(), observe: 'response', responseType: 'json' }
    ).pipe(map((resp: any) => {
      // intercepting the version headers and notifying the Subject
      this.updateBuildInfo(resp)
      return resp.body
    }))
  }

  private getTimeZone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
      return 'Europe/London'
    }
  }

  private getBaseHeaders() {
    const baseProps: Properties = {
      'accept': 'application/json',
      'content-type': 'application/json',
      'time-zone': this.getTimeZone(),
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Vary': '*'
    }
    if (this.token) baseProps["x-authorization-token"]= `Bearer ${this.token}`
    return this.eventuallyFillWithAcceptLanguage(baseProps);
  }

  private headers() {
    return new HttpHeaders(this.getBaseHeaders());
  }

  private headersDelete() {
    const baseProps: Properties = {
      'accept': 'application/json',
      'time-zone': this.getTimeZone()
    }
    if (this.token) baseProps["x-authorization-token"]= `Bearer ${this.token}`
    return new HttpHeaders(this.eventuallyFillWithAcceptLanguage(baseProps))
  }
}

export function isLoginResponse(obj: any): obj is LoginResponse {
  if (obj != null && typeof obj.token === 'string') {
    return true
  }
  return false
}
