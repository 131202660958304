import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService, Event, Prop } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { ErrorService } from 'app/utils/error-handling/error.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { treatmentOptimiser, Tool } from '../../interfaces/theme.interface'
import { DialogConfig, DialogVariant } from '../dialog/dialog-config'
import { DialogRef } from '../dialog/dialog-ref'
import { DialogService } from '../dialog/dialog.service'
import { FirstTimeTourComponent } from '../first-time-tour/first-time-tour.component'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-start-consultation',
  templateUrl: './start-consultation.component.html',
  styleUrls: ['./start-consultation.component.scss'],
  animations: FadeInAnimation.animations
})
export class StartConsultationComponent implements OnInit, OnDestroy {
  private static readonly TAG = 'comp::StartConsultation'
  public navBackBarVariant = NavBackBarVariant
  public patientDataForm: FormGroup
  public formSubmitAttempt = false
  private tourTimeout = null
  public userSettings: SettingsResponse
  public firstName = ''
  public brandName = ''
  public activeTool: string
  public maskInputs: boolean = true
  public expandedText = false
  public treatmentOptimiser: string = treatmentOptimiser.name
  public isPatientDetails: boolean = false
  public maxHeight: number = 300
  private maxWeight: number = 500
  private maxAge: number = 95
  private minAge: number = 18
  private asthmaDialog: DialogRef = null

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private navigation: NavigationService,
    public router: Router,
    private dialog: DialogService,
    private localData: LocalDataService,
    private utilService: UtilitiesService,
    private communication: CommunicationService,
    private errorService: ErrorService,
    private analyticsService: AnalyticsService,
    public dialogService: DialogService
  ) {
  }

  public async ngOnInit() {
    this.activeTool = this.localData.getLastActiveTool()
    this.patientDataForm = this.fb.group({
      id: ['', [
        Validators.required
      ]],
      age: [null, [
        Validators.min(12),
        Validators.max(95)
      ]],
      sex: [''],
      height: [null, [
        Validators.min(1),
        Validators.max(300)
      ]],
      weight: [null, [
        Validators.min(0.1),
        Validators.max(500)
      ]],
      headPractitioner: ['']
    })
    this.utilService.clearSecondaryCare()
    try {
      this.userSettings = await this.communication.getUserSettings()
      this.firstName = this.userSettings.user.firstName
      this.localData.setLocale({
        country: this.userSettings.user.countryCode
      })
      const { detected, config } = await this.communication.getCountryConfig()
      const detectedCountry = config.find(e => e.code === detected.country)

      if (detectedCountry && detectedCountry.branding) {
        this.brandName = detectedCountry.branding.label + '+'
      } else {
        this.brandName = 'COPD Optimiser'
      }

      // check if the current tool is still available for this country
      if (!detectedCountry || !detectedCountry.variants.includes(this.treatmentOptimiser)) {
        this.navigation.logout()
        clearTimeout(this.tourTimeout)
        await this.router.navigate(['/'])
      }
    } catch (e) {
      await this.errorService.handleError(e)
    }
    const hasVisitTour = this.localData.getFirstTimeTour()
    if (!hasVisitTour) {
      this.tourTimeout = setTimeout(() => this.takeTour(), 1500)
    } else {
      this.maskInputs = false
    }
  }

  public takeTour() {
    clearTimeout(this.tourTimeout)
    this.maskInputs = false
    const config = new DialogConfig<any>()
    config.withButtons = false
    config.fixedHeight = true
    config.variant = DialogVariant.FirstTimeTour
    this.dialogService.open(FirstTimeTourComponent, config)
  }

  public increaseAge() {
    if (this.patientDataForm.controls.age.value < this.maxAge) {
      if (this.patientDataForm.controls.age.value === null || this.patientDataForm.controls.age.value < this.minAge) {
        this.patientDataForm.controls.age.setValue(this.minAge);
      } else {
        this.patientDataForm.controls.age.setValue(this.patientDataForm.controls.age.value + 1);
      }
    }
  }

  public decreaseAge() {
    if (this.patientDataForm.controls.age.value > this.maxAge) {
      this.patientDataForm.controls.age.setValue(this.maxAge)
    } else {
      this.patientDataForm.controls.age.setValue(Math.max(this.patientDataForm.controls.age.value - 1, this.minAge));
    }
  }

  public increaseHeight() {
    if (this.patientDataForm.controls.height.value < this.maxHeight) {
      this.patientDataForm.controls.height.setValue(this.patientDataForm.controls.height.value + 1);
    }
  }

  public decreaseHeight() {
    if (this.patientDataForm.controls.height.value > this.maxHeight) {
      this.patientDataForm.controls.height.setValue(this.maxHeight)
    } else {
      this.patientDataForm.controls.height.setValue(Math.max(this.patientDataForm.controls.height.value - 1, 1));
    }
  }

  public increaseWeight() {
    if (this.patientDataForm.controls.weight.value < this.maxWeight) {
      this.patientDataForm.controls.weight.setValue((Math.round((this.patientDataForm.controls.weight.value + 0.1) * 10)) / 10);
    }
  }

  public decreaseWeight() {
    if (this.patientDataForm.controls.weight.value > this.maxWeight) {
      this.patientDataForm.controls.weight.setValue(this.maxWeight)
    } else {
      this.patientDataForm.controls.weight.setValue((Math.round(Math.max(this.patientDataForm.controls.weight.value - 0.1, 0.1) * 10)) / 10);
    }
  }

  private askForAsthma() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.noPrimary = false
    config.title = this.translate.instant('login.startConsultation.asthmaDialog.title')
    config.subTitle = this.translate.instant('login.startConsultation.asthmaDialog.subTitle')
    config.doneLabel = this.translate.instant('login.startConsultation.asthmaDialog.doneLabel')
    config.cancelLabel = this.translate.instant('login.startConsultation.asthmaDialog.cancelLabel')
    this.asthmaDialog = this.dialog.open(null, config)
    this.asthmaDialog.done.subscribe(() => {
      this.asthmaDialog.close()
      this.startConsultation()
    })
    this.asthmaDialog.cancel.subscribe(() => this.asthmaDialog.close())
  }

  public async startConsultation() {
   try {
      const { detected, config } = await this.communication.getCountryConfig()
      const detectedCountry = config.find(e => e.code === detected.country)
      const data = {
        context: {
          team: {
            patientId: this.patientDataForm.controls.id.value,
            patientDetails: {
              patientAge: this.patientDataForm.controls.age.value,
              patientSex: this.patientDataForm.controls.sex.value,
              patientHeight: this.patientDataForm.controls.height.value !== null ? (this.patientDataForm.controls.height.value / 100) : null,
              patientWeight: this.patientDataForm.controls.weight.value,
              headPractitioner: this.patientDataForm.controls.headPractitioner.value
            },
            hcpName: `${this.userSettings.user.firstName} ${this.userSettings.user.lastName}`,
            veeva: this.translate.instant('localID_treatmentoptimiser'),
            referralLetterBtn: detectedCountry.referralLetter
          }
        }
      }

      await this.navigation.startConsultation(data)
      this.analyticsService.event(Event.StartConsultation, {
        value1: this.userSettings.guideline,
        user_language: this.userSettings.language,
        value2: this.userSettings.lungFunction
      })
    } catch (e) {
      this.errorService.handleError(e)
    }
  }

  public onSubmit() {
    if (this.patientDataForm.valid) {
      this.navigation.resetContext()
      this.askForAsthma()
      // this.startConsultation()
    } else {
      this.formSubmitAttempt = true
    }
  }

  public onExpandText(e) {
    e.preventDefault()
    this.expandedText = true
  }

  public showPatientDetails() {
    this.isPatientDetails = !this.isPatientDetails
  }

  public openGuidelinesDialog(e) {
    e.preventDefault()
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      const config = new DialogConfig()
      config.backDrop = true
      config.title = this.translate.instant('login.guidelines.modal.title')
      const key = 'login.guidelines.modal.subtitle'
      const contentKey = 'login.guidelines.modal.content'
      config.subTitle = this.translate.instant(key)
      config.content = this.translate.instant(contentKey)
      config.withButtons = false
      this.dialog.open(null, config)
    }
  }

  public ngOnDestroy() {
    clearTimeout(this.tourTimeout)
  }
}
