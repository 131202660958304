import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { LocalDataService } from 'app/services/local-data.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnChanges {
  @Input() public content: any
  public apiHost = environment.API_HOST
  public lang = this.localDataService.getLocale().language
  public shortLinks = []

  constructor(private localDataService: LocalDataService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const item of this.content) {
      const shortLink = item.data.link ? new URL(item.data.link).hostname : '';
      this.shortLinks.push(shortLink)
    }
  }
}
