const basicInfo = {
  sections: [{
    description: null,
    additionalInfo: null,
    fields: [{
      id: 'firstName',
      required: true,
      selected: null,
      name: 'firstName',
      type: 'text',
      forceOneColumn: true,
      value: '',
      placeholder: 'First Name',
      validations: {
        required: true
      },
      readonly: true
    },
    {
      id: 'lastName',
      required: true,
      selected: null,
      name: 'lastName',
      type: 'text',
      forceOneColumn: true,
      value: '',
      placeholder: 'Surname',
      validations: {
        required: true
      },
      readonly: true
    },
    {
      id: 'email',
      required: true,
      selected: null,
      name: 'email',
      type: 'email',
      forceOneColumn: true,
      value: '',
      placeholder: 'Email',
      validations: {
        required: true
      },
      readonly: true
    },
    {
      id: 'country',
      required: true,
      selected: null,
      name: 'country',
      type: 'text',
      forceOneColumn: true,
      value: '',
      placeholder: 'Country',
      readonly: true,
      validations: {
        required: true
      }
    },
    {
      id: 'language',
      required: true,
      selected: null,
      name: 'language',
      type: 'select',
      placeholder: 'Language',
      value: 'en',
      validations: {
        required: true
      },
      options: [
      ]
    },
    {
      id: 'helpAndSupport',
      type: 'plain-text'
    }]
  } /*{
    description: '',
    additionalInfo: null,
    fields: [{
      id: 'role',
      options: [
        {
          id: 'GP',
          value: 'GP'
        },
        {
          id: 'NURSE',
          value: 'NURSE'
        },
        {
          id: 'ALLERGIST',
          value: 'ALLERGIST'
        },
        {
          id: 'PHARMACIST',
          value: 'PHARMACIST'
        },
        {
          id: 'RESPIRATORY_SPEC',
          value: 'RESPIRATORY_SPEC'
        },
        {
          id: 'OTHER_SPEC',
          value: 'OTHER_SPEC'
        }
      ],
      required: true,
      selected: null,
      name: 'role',
      type: 'radio',
      value: '',
      validations: {
        required: true
      }
    }]
  }, {
    additionalInfo: null,
    fields: [{
      id: 'guideline',
      options: [
        {
          id: '1',
          label: 'GINA',
          value: 'GINA',
          description: true
        },
        {
          id: '2',
          label: 'RCP3Q',
          value: 'RCP3Q',
          description: true
        },
        {
          id: '3',
          label: 'ACQ6',
          value: 'ACQ6',
          description: true
        }
      ],
      selected: null,
      name: 'guideline',
      type: 'radio',
      forceOneColumn: true,
      value: '',
      validations: {
        required: true
      }
    }]
  }, {
    additionalInfo: '',
    fields: [{
      id: 'lungFunction',
      options: [
        {
          id: '1',
          value: 'PEAKFLOW',
          description: true
        },
        {
          id: '2',
          value: 'SPIROMETRY',
          description: true
        }
      ],
      selected: null,
      name: 'lungFunction',
      type: 'radio',
      value: '',
      forceOneColumn: true,
      validations: {
        required: true
      }
    }]
  }*/
  ]
}

export default { basicInfo }
