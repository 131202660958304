import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogService } from 'app/components/dialog/dialog.service'

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
  })
  export class DisclaimerComponent {
  @Input() public treatmentOptimiser = false
  constructor(private dialog: DialogService,
              private translate: TranslateService) {
  }
  public linkClicked(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      e.preventDefault()
      this.openExpertsDialog()
    }
  }

  public openExpertsDialog() {
    const config = new DialogConfig()
    config.backDrop = true
    config.title = this.translate.instant('login.an.experts.modal.title')
    config.subTitle = this.translate.instant('login.an.experts.modal.subtitle')
    config.content = this.translate.instant('login.an.experts.modal.content')
    config.withButtons = false
    this.dialog.open(null, config)
  }
}
