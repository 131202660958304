import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { treatmentOptimiser, referralIdentifier } from 'app/interfaces/theme.interface'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {
  public referralIdentifier: string = referralIdentifier.name
  public treatmentOptimiser: string = treatmentOptimiser.name
  constructor(private router: Router,
              private localData: LocalDataService,
              private translate: TranslateService,
              private errorService: ErrorService,
              private communication: CommunicationService) {
  }

  public async canActivate(): Promise<boolean> {
    const { country, language } = this.localData.getLocale()
    const initialSettings = !!(country && language)
    if (!initialSettings) {
      await this.router.navigate(['/'])
      return false
    } else {
      this.localData.setLocale({ country, language })
      const lastActiveTool = this.localData.getLastActiveTool()
      if (!lastActiveTool) {
        await this.router.navigate(['/select-tool'])
        return false
      } else if (lastActiveTool === this.treatmentOptimiser) {
        // try autologin
        try {
          const token = await this.communication.autoLogin()
          if (token) {
            await this.router.navigate(['/start-consultation'])
            return false
          } else {
            return true
          }
        } catch (e) {
          await this.errorService.handleError(e)
          return false
        }
      } else {
        return true
      }
    }
  }
}
